import { Injectable } from '@angular/core';
import {SupportFunction} from "../models/Support/SupportFunction";


@Injectable({
  providedIn: 'root'
})
export class SupportService {

  public supportFunctions: SupportFunction[] = [
    {
      url: "/auth/login",
      problem_message: "logging in",
      solution_message: "Our support team can help you get back into your account."
    },
    {
      url: "/auth/forgot-password-core",
      problem_message: "resetting your password",
      solution_message: "Our support team can help you get back into your account."
    },
    {
      url: "/core/influencers/search",
      problem_message: "searching for influencers",
      solution_message: "For advice or to report a problem with our search, speak to our support team."
    },
    {
      url: "/core/influencers/search/",
      problem_message: "analyzing influencers",
      solution_message: "We're here to help you understand the influencer reports."
    },
    {
      url: "/core/influencers/recent",
      problem_message: "accessing recently analyzed influencers.",
      solution_message: "If you need help or want to report a problem with this feature, our support team are here to help."
    },
    {
      url: "/core/influencers/shortlists",
      problem_message: "managing influencer shortlists",
      solution_message: "If you need help or want to report a problem with this feature, our support team are here to help."
    },
    {
      url: "/core/campaigns",
      problem_message: "viewing your campaigns",
      solution_message: "If you need help or want to report a problem with this feature, our support team are here to help."
    },
    {
      url: "/core/campaigns/create",
      problem_message: "creating a new campaign",
      solution_message: "If you need help or want to report a problem with this feature, our support team are here to help."
    },
    {
      url: "/core/brands",
      problem_message: "viewing your brands",
      solution_message: "If you need help or want to report a problem with this feature, our support team are here to help."
    },
    {
      url: "/core/brands/create",
      problem_message: "adding a new brand",
      solution_message: "If you need help or want to report a problem with this feature, our support team are here to help."
    },
    {
      url: "/core/clients",
      problem_message: "viewing your clients",
      solution_message: "If you need help or want to report a problem with this feature, our support team are here to help."
    },
    {
      url: "/core/clients/create",
      problem_message: "adding a new client",
      solution_message: "If you need help or want to report a problem with this feature, our support team are here to help."
    },
    {
      url: "/core/emails",
      problem_message: "managing your e-mail templates",
      solution_message: "If you need help or want to report a problem with this feature, our support team are here to help."
    },
    {
      url: "/core/emails/create",
      problem_message: "creating an e-mail template",
      solution_message: "If you need help building e-mail templates, or want to learn more about merge fields, you visit our knowledge base, alternatively our support team are here to assist you further.."
    },
    {
      url: "/core/admin/users",
      problem_message: "managing your users",
      solution_message: "If you need help or want to report a problem with this feature, our support team are here to help."
    },
  ]

  constructor() { }

  retrieveProblemMessage(url:string): string{
      const problem = this.supportFunctions.find((item) => item.url === url);
      if(problem){
        return problem.problem_message;
      }else{
        return 'the platform';
      }
  }

  retrieveSolutionMessage(url:string): string{
    const solution = this.supportFunctions.find((item) => item.url === url);
    if(solution){
      return solution.solution_message;
    }else{
      return "If you need help or want to report a problem with this feature, our support team are here to help.";
    }
  }

}
