import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CookieService} from "ngx-cookie-service";
import {TenantService} from "./tenant.service";
import {TokenStore} from "../modules/auth/models/TokenStore";
import { DateTime } from "luxon";
import {SubscriptionStatus} from "../models/SubscriptionStatus";
import {CurrentUser} from "../models/CurrentUser";

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {

  currentUser$: Observable<CurrentUser | any |  null>;
  currentUserSubject: BehaviorSubject<CurrentUser | any | null>;
  currentPaymentStatus$: Observable<SubscriptionStatus | null>;
  currentPaymentStatusSubject: BehaviorSubject<SubscriptionStatus | null>;
  currentToken$: Observable<TokenStore | null>;
  currentTokenSubject: BehaviorSubject<TokenStore | null>;

  currentPackage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  currentTokenBalance: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  currentFeatures: BehaviorSubject<any> = new BehaviorSubject<any>({});

  currentUserType: BehaviorSubject<string>;

  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  redirects = {
    core: '/',
    influencer: '/influencers/campaigns',
    client: '/clients/campaigns',
  }

  constructor(
    private http: HttpClient,
    public cookieService: CookieService,
    public tenant: TenantService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserType = new BehaviorSubject<string>('');
    this.currentUserSubject = new BehaviorSubject<CurrentUser | any | null>(null);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.currentTokenSubject = new BehaviorSubject<TokenStore | null>(null);
    this.currentToken$ = this.currentTokenSubject.asObservable();
    this.currentPaymentStatusSubject = new BehaviorSubject<any>(undefined);
    this.currentPaymentStatus$ = this.currentPaymentStatusSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: any) {
    this.currentUserSubject.next(user);
  }

  get currentTokenValue(): any {
    return this.currentTokenSubject.value;
  }

  set currentTokenValue(user: any) {
    this.currentTokenSubject.next(user);
  }

  // public methods
  login(loginData: any, type : 'core' | 'influencer' | 'client' = 'core'): Promise<any> {

    loginData.client_id = environment.clientIDs[type];
    loginData.grant_type = 'password';
    loginData.scope = '';

    this.isLoadingSubject.next(true);
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/oauth/token', loginData, this.tenant.appendTenantHeader())
        .subscribe(
          (res) => {
            this.isLoadingSubject.next(false);
            resolve(res);
          },
          err => {
            this.isLoadingSubject.next(false);
            reject(err);
          },
        );
    });

  }

  requestNewPassword(data: any, type: 'core' | 'influencer' | 'client' = 'core'){

    data.type = type;

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/tenant-api/reset/request', data, this.tenant.appendTenantHeader())
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  checkResetToken(data: any){

      return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/tenant-api/reset/code', data, this.tenant.appendTenantHeader())
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  changePasswordReset(data: any){

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/tenant-api/reset/reset', data, this.tenant.appendTenantHeader())
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  renewToken(data: TokenStore, type : 'core' | 'influencer' | 'client' = 'core'): Promise<any> {

    const TokenRequestData = {
      grant_type: 'refresh_token',
      refresh_token : data.refresh_token,
      client_id: environment.clientIDs[type],
      scope: ''
    }

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/oauth/token', TokenRequestData, this.tenant.appendTenantHeader())
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  logout() {

    this.destroySession();
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  destroySession() {
    this.cookieService.delete('socialyze_token_data');

    // INFLUENCERS AND CLIENTS DON'T NEED TO DELETE THIS AS THEY ONLY LOG INTO ONE PLACE
    if(this.userType === 'core'){
      this.cookieService.delete('socialyze_tenant');
      this.cookieService.delete('socialyze_domain');
      this.tenant.currentTenantSubject.next(null);
      this.tenant.currentDomainSubject.next(null);
    }

    this.currentUserSubject.next(null);
    this.currentTokenSubject.next(null);
    this.currentPaymentStatusSubject.next(null);

  }

  ngOnDestroy() {
  }

  public saveTokenToStorage(data: TokenStore): TokenStore{
    data.expires_at = DateTime.now().plus({seconds: (data.expires_in - 100)}).toJSDate();
    this.cookieService.set('socialyze_token_data', JSON.stringify(data), {path: '/', sameSite: 'Lax'});
    return data;
  }

  public saveUserType(data: 'core' | 'client' | 'influencer'){
    this.cookieService.set('socialyze_type', data, {path: '/', sameSite: 'Lax'});
    return data;
  }

  get userType(): 'core' | 'client' | 'influencer'{
    return this.cookieService.get('socialyze_type') as 'core' | 'client' | 'influencer';
  }

  set userType(data: 'core' | 'client' | 'influencer'){
    this.cookieService.set('socialyze_type', data, {path: '/', sameSite: 'Lax'});
  }

  public retrieveTokenFromStorage(): TokenStore | null{
    if(this.cookieService.check('socialyze_token_data')){
      return JSON.parse(this.cookieService.get('socialyze_token_data'))
    }else{
      return null;
    }
  }

  containsCustomDomain(data: string){

    return data.indexOf('.')> -1

  }


}
