import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import {CookieService} from "ngx-cookie-service";
import {ApiService} from "../services/api.service";
import {TenantService} from "../services/tenant.service";
import {WhiteLabelService} from "../services/white-label.service";

@Injectable({
  providedIn: 'root'
})
export class ApplyPublicTenant implements CanActivate, CanActivateChild {

  constructor(
    public router: Router,
    public cookieService: CookieService,
    public tenant: TenantService,
    public whiteLabel: WhiteLabelService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise((resolve) => {
      // IF IT'S ALREADY BEEN BROUGHT IN FROM COOKIES, ALLOW IT

      if(route.params.tenant){
        this.cookieService.set('socialyze_tenant', route.params.tenant, {path: '/', sameSite: 'Lax'});
      }

      this.getDomainInformation(route.params.tenant).then((res)=>{
        resolve(true);
      });


    });

  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise((resolve) => {

      if(route.params.tenant){
        this.cookieService.set('socialyze_tenant', route.params.tenant, {path: '/', sameSite: 'Lax'});
      }

      this.getDomainInformation(route.params.tenant).then((res)=>{
        resolve(true);
      });

    });

  }

  getDomainInformation(tenant: string): Promise<any>{

    return new Promise((resolve) => {

      this.tenant.reverseLookup({tenant: tenant}).then((TenantResult)=>{
        this.cookieService.set('socialyze_domain', TenantResult.Domain.domain, {path: '/', sameSite: "Lax"});
        resolve(true);
      }).catch((TenantError)=>{
        console.log(TenantError);
      });

    });

  }



}
