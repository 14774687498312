import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {AuthService} from "./auth.service";
import {Subscription} from "rxjs";
import {TokenStore} from "../modules/auth/models/TokenStore";

@Injectable({
  providedIn: 'root'
})
export class ApiService implements OnDestroy{

  authenticatedOptions: any;
  unAuthenticated: any;
  uploadOptions: any;
  downloadOptions: any;
  tokenSubscription: Subscription;

  constructor(public http: HttpClient, private auth: AuthService) {
    this.tokenSubscription = this.auth.currentToken$.subscribe((data: TokenStore | null) => {
      if(data){
        this.authenticatedOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            "X-Tenant" : this.auth.tenant.currentTenantValue,
            Authorization: 'Bearer ' + data.access_token
          }),
        };
        this.downloadOptions = {
          responseType: 'blob',
          headers: new HttpHeaders({
            "X-Tenant" : this.auth.tenant.currentTenantValue,
            Authorization: 'Bearer ' + data.access_token
          }),
        };
        this.uploadOptions = {
          headers: new HttpHeaders({
            Accept: 'application/json',
            "X-Tenant" : this.auth.tenant.currentTenantValue,
            Authorization: 'Bearer ' + data.access_token
          }),
        };
      }
    });
  }

  ngOnDestroy(): void {
    this.tokenSubscription.unsubscribe();
  }

  getIframeURL(addressType: string, backgroundColor: string, labelColor: string): string{

    return  environment.httpProtocol +
            this.auth.tenant.currentTenantValue +
            environment.tenant_domain + '/enquire?' +
            'addressType=' + addressType +
            '&bgColor=' + backgroundColor +
            '&labelColor=' + labelColor;
  }

  getAPIUrl(): string{

    return  environment.httpProtocol +
            this.auth.tenant.currentTenantValue +
            environment.tenant_domain + '/tenant-api/leads';
  }

  getSettings(): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/tenant-api/' + this.auth.currentUserType.value + '/settings', this.authenticatedOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  apiGet(url: string): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/tenant-api/' + this.auth.currentUserType.value + url, this.authenticatedOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  apiDownload(url: string): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/tenant-api/' + this.auth.currentUserType.value + url, this.downloadOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  mediaDownload(url: string): Promise<any>{

    console.log('Connecting to Media Server: ' + environment.media_domain);

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.httpProtocol + environment.media_domain + '/tenant-api/' + this.auth.currentUserType.value + url, this.downloadOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  apiPost(url: string, data: any, upload: boolean = false): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/tenant-api/' + this.auth.currentUserType.value + url, data, !upload ? this.authenticatedOptions : this.uploadOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  apiPut(url: string): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .put(environment.httpProtocol + environment.tenant_domain + '/tenant-api/' + this.auth.currentUserType.value + url, {}, this.authenticatedOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  apiDelete(url: string): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .delete(environment.httpProtocol + environment.tenant_domain + '/tenant-api/' + this.auth.currentUserType.value + url, this.authenticatedOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  getLoggedInUser(): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/tenant-api/' + this.auth.currentUserType.value + '/settings/user', this.authenticatedOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  getPublicInvitationData(invitationID: string): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/tenant-api/invitations/' + invitationID,{
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            "X-Tenant" : this.auth.tenant.currentTenantValue
          }),
        })
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  publicPost(url: string, data: any): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/tenant-api/' + url,  data,{
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            "X-Tenant" : this.auth.tenant.currentTenantValue
          }),
        })
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  submitTicket(url: string, data: any): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/tenant-api/submitTicket', data, this.authenticatedOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }



}
