import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import {CookieService} from "ngx-cookie-service";
import {ApiService} from "../services/api.service";
import {TenantService} from "../services/tenant.service";
import {WhiteLabelService} from "../services/white-label.service";

@Injectable({
  providedIn: 'root'
})
export class TenantGuard implements CanActivate, CanActivateChild {

  constructor(
    public router: Router,
    public cookieService: CookieService,
    public tenant: TenantService,
    public whiteLabel: WhiteLabelService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise((resolve) => {
      // IF IT'S ALREADY BEEN BROUGHT IN FROM COOKIES, ALLOW IT

      if(this.tenant.currentTenantValue){
        resolve(true);
      }else{

        // CHECK THE COOKIES
        if(!this.cookieService.get('socialyze_tenant') || !this.cookieService.get('socialyze_domain')){

          console.log(route.queryParams)

          if(route.queryParams.tenant){

            console.log('TENANT QUERY PARAM FOUND');

            this.cookieService.set('socialyze_tenant', route.queryParams.tenant, {path: '/', sameSite: 'Lax'});

            this.getDomainInformation(route.queryParams.tenant).then((res)=>{
              this.tenant.currentTenantSubject.next(route.queryParams.tenant);
              this.tenant.currentDomainValue = this.cookieService.get('socialyze_domain');

              this.tenant.getPublicBranding().then((WhiteLabelSettings)=>{
                this.whiteLabel.handleDownloadedSettings(WhiteLabelSettings).then(()=>{
                  resolve (true);
                });
              }).catch((WhiteLabelError)=>{
                console.log(WhiteLabelError)
                resolve(true);
              });

            });

          }else{
            // NO TENANT FOUND, REDIRECT TO IDENTIFY SCREEN
            this.router.navigateByUrl('/auth/identify').then(()=>{
              resolve(false);
            });
          }

        }else{

          // WE FOUND IT IN THE COOKIES, ALLOW IT.
          this.tenant.currentTenantValue = this.cookieService.get('socialyze_tenant');
          this.tenant.currentDomainValue = this.cookieService.get('socialyze_domain');

          this.tenant.getPublicBranding().then((WhiteLabelSettings)=>{
              this.whiteLabel.handleDownloadedSettings(WhiteLabelSettings).then(()=>{
                // SKINNING COMPLETE, LOAD UI
                resolve(true);
              })
          }).catch((WhiteLabelError)=>{
            // PROCEED UNBRANDED
            resolve(true);
          });

        }

      }

    });

  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise((resolve) => {

      // IF IT'S ALREADY BEEN BROUGHT IN FROM COOKIES, ALLOW IT
      if(this.tenant.currentTenantValue){
        resolve(true);
      }

      // CHECK THE COOKIES
      if(!this.cookieService.get('socialyze_tenant') || !this.cookieService.get('socialyze_domain')){

        if(route.queryParams.tenant){
          this.cookieService.set('socialyze_tenant', route.queryParams.tenant, {path: '/', sameSite: 'Lax'});

          this.getDomainInformation(route.queryParams.tenant).then((res)=>{
            this.tenant.currentTenantSubject.next(route.queryParams.tenant);
            this.tenant.currentDomainValue = this.cookieService.get('socialyze_domain');

            this.tenant.getPublicBranding().then((WhiteLabelSettings)=>{
              this.whiteLabel.handleDownloadedSettings(WhiteLabelSettings).then(()=>{
                resolve (true);
              });
            }).catch((WhiteLabelError)=>{
              console.log(WhiteLabelError)
              resolve(true);
            });

          });

        }else{
          // NO TENANT FOUND, REDIRECT TO IDENTIFY SCREEN
          this.router.navigateByUrl('/auth/identify').then(()=>{
            resolve(false);
          });
        }

      }else{

        // WE FOUND IT IN THE COOKIES, ALLOW IT.
        this.tenant.currentTenantValue = this.cookieService.get('socialyze_tenant');
        this.tenant.currentDomainValue = this.cookieService.get('socialyze_domain');

        this.tenant.getPublicBranding().then((WhiteLabelSettings)=>{
          this.whiteLabel.handleDownloadedSettings(WhiteLabelSettings).then(()=>{
            // SKINNING COMPLETE, LOAD UI
            resolve(true);
          })
        }).catch((WhiteLabelError)=>{
          // PROCEED UNBRANDED
          resolve(true);
        });

      }

    });

  }


  getDomainInformation(tenant: string): Promise<any>{

    return new Promise((resolve) => {

      this.tenant.reverseLookup({tenant: tenant}).then((TenantResult)=>{
        this.cookieService.set('socialyze_domain', TenantResult.Domain.domain, {path: '/', sameSite: "Lax"});
        resolve(true);
      }).catch((TenantError)=>{
        console.log(TenantError);
      });

    });

  }

}
