import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {CookieService} from "ngx-cookie-service";
import {TenantService} from "../services/tenant.service";
import {ApiService} from "../services/api.service";
import {WhiteLabelService} from "../services/white-label.service";

@Injectable({
  providedIn: 'root'
})
export class RedirectGuardGuard implements CanActivate {

  constructor(
    public router: Router,
    public cookieService: CookieService,
    public tenant: TenantService,
    public api: ApiService,
    public whiteLabel: WhiteLabelService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise <boolean> ((resolve)=>{
        const type = this.cookieService.get('socialyze_type');

        if(type === 'core'){
          this.router.navigateByUrl('/core/influencers')
        }

        if(type === 'influencer'){
          this.router.navigateByUrl('/influencers/campaigns')
        }

        if(type === 'client'){
          this.router.navigateByUrl('/clients/campaigns')
        }


    })
  }

}
