import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot, UrlTree, Router,
} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {TokenStore} from "../modules/auth/models/TokenStore";
import {Observable} from "rxjs";
import {DateTime} from "luxon";
import {ApiService} from "../services/api.service";
import {SettingsService} from "../services/settings.service";

@Injectable({providedIn: 'root'})
export class RetrieveSession implements CanActivate {
  constructor(private api: ApiService, private auth: AuthService, private settings: SettingsService, public router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise(async (resolve) => {
      if(this.auth.currentUserValue && this.settings.settings){
        // THIS GUARD HAS ALREADY RUN SUCCESSFULLY, SO JUST LET THEM THROUGH
        resolve(true);
      }else{

        // GET SETTINGS AND USERS FROM THE SERVER
        const promiseArray =[
          this.api.getSettings(),
          this.api.getLoggedInUser()
        ];

        Promise.all(promiseArray).then((SessionData) => {
          console.log(SessionData);
          this.settings.settings = SessionData[0];
          this.auth.currentUserValue = SessionData[1];
          resolve(true);
        }).catch((err)=>{
          resolve(false);
        });

      }

    });
  }

}
